import {
    CloseOutlined,
    ExpandMore,
    AddRounded,
    RemoveRounded
} from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    Badge,
    ButtonGroup,
    TextField,
    Button
} from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IItem, IPresentTemplate } from "../../../../const";
import { useGlobalContext } from "../../../../context/Global";
import { useTrackContext } from "../../../../context/Tracking";
import { showPrice } from "../../../../utils/price";
import { Section as LocalSection } from "../../../../utils/sections";
import { Root, classes } from "./styles";
/* eslint-disable import/no-webpack-loader-syntax */
import Delete from "-!svg-react-loader!../../../../assets/icons/trash.svg";
import { Box } from "@mui/system";
import { CartItem } from "../../../../utils/cartItems";
import AddToCart from "-!svg-react-loader!../../../../assets/icons/addtocart.svg";
import EditIcon from "-!svg-react-loader!../../../../assets/icons/editIcon.svg";
import { Collections } from "../../../../utils/collection";
import { useIntl } from "react-intl";

export default function CartSection(props: {
    launchEvents?: boolean;
    sectionTitle: string;
    sectionDescription: string;
    sectionAlwaysOpen?: boolean;
    items: Array<{
        item_id: IItem;
    }>;
    showNotations: boolean;
    defaultExpanded: boolean;
    alwaysExpanded?: boolean;
    id: string;
    internalName: string;
    template: Partial<IPresentTemplate>;
    setModifyPopupPos: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const {
        sectionTitle,
        sectionDescription,
        sectionAlwaysOpen = false,
        items,
        showNotations,
        defaultExpanded,
        alwaysExpanded = false,
        id,
        internalName,
        launchEvents = true,
        template,
        setModifyPopupPos = false
    } = props;
    const { fullMenu, menu, cartData, setCartData } = useGlobalContext();

    const collectionId = new Collections(`${fullMenu?.identifier}`);
    const current_collection = collectionId.getcollection();
    const [imagesState, setImagesState] = useState<{
        [key: string]: boolean;
    }>({});
    const [cartItems, setCartItems] = useState([...items]);
    const sections = new LocalSection(`${fullMenu?.identifier}`);

    const [expanded, setExpanded] = React.useState<boolean>(alwaysExpanded);
    const { newEvent } = useTrackContext();
    const { restaurant: restaurantIdentifier } = useParams();
    const [modifyPopup, setModifyPopup] = useState<{ [key: number]: boolean }>(
        {}
    );

    const cartItem = new CartItem(`${fullMenu?.identifier}`);
    const navigate = useNavigate();

    const intl = useIntl();

    const [counts, setCounts] = useState(
        cartData?.map((item: any) => ({
            _id: item?._id, // using _id as key
            item_length: item?.item_length
        }))
    );

    useEffect(() => {
        // Create a new array based on the order of cartData
        const orderedCartItems: any = cartData?.map((dataItem: any) =>
            items?.find((item: any) => item.item_id._id === dataItem?._id)
        );
        setCartItems(orderedCartItems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    useEffect(() => {
        setCounts(
            cartData?.map((item: any) => ({
                _id: item?._id, // Key: _id of the item
                item_length: item?.item_length // Value: item_length of the item
            }))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartItems]);

    React.useEffect(() => {
        setExpanded(getSectionState());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menu]);

    function getPriceSeparator(item: { item_id: IItem }) {
        if (!_.get(template, "controls.show_item_price", true)) {
            return <></>;
        }
        switch (item.item_id.price_type) {
            case "slash":
                return <span> / </span>;
            default:
                return (
                    <span
                        style={{
                            marginBottom: "5px",
                            display: "block"
                        }}
                    ></span>
                );
        }
    }

    function getSectionState() {
        // If is allways expanded just return true
        if (alwaysExpanded) {
            return true;
        }
        // If the menu has activated the section expanded, it must overrite everything
        if (menu?.menu_id?.section_expanded) {
            return true;
        }
        // If the sectionAlwaysOpen is true, it must overrite everything
        if (sectionAlwaysOpen) {
            return true;
        }
        // If we have the section state saved previously
        if (typeof sections.getSections()[id] === "boolean") {
            return sections.getSections()[id];
        }
        // If has default open use it
        return defaultExpanded;
    }

    function handleRemoveItem(item: { item_id: IItem }) {
        const matchingObject = fullMenu?.collection_links.find(
            (item) => item?.collection_id?._id === current_collection
        );

        const firstMenuId =
            matchingObject?.collection_id.menu_links[0]?.menu_id._id ||
            fullMenu?.collection_links[0]?.collection_id.menu_links[0]?.menu_id
                ._id;

        const currentCollection =
            matchingObject?.collection_id._id ||
            fullMenu?.collection_links[0]?.collection_id._id;

        const navigateUrl = `/${fullMenu?.identifier}/${currentCollection}/${firstMenuId}?order=on`;

        const exists = Boolean(
            cartData?.filter(
                (cartItem: any) => cartItem?._id === item?.item_id?._id
            ).length
        );
        if (exists) {
            addFavoriteEvent(item, "item-unfavorite");
            setCartItems((prevItems) =>
                prevItems.filter(
                    (pItem) => pItem?.item_id?._id !== item?.item_id?._id
                )
            );

            return (
                setCartData &&
                    setCartData(cartItem.removeCartItem(item?.item_id?._id)),
                cartData?.length === 1 && navigate(navigateUrl)
            );
        }
    }

    function addFavoriteEvent(
        item: { item_id: IItem },
        eventType: "item-favorite" | "item-unfavorite"
    ) {
        launchEvents &&
            newEvent(
                eventType,
                {
                    _id: item?.item_id?._id,
                    name:
                        item?.item_id?.internal_name ||
                        item?.item_id?.display_name,
                    template: "1"
                },
                restaurantIdentifier || ""
            );
    }

    function launchExpandEvent() {
        launchEvents &&
            newEvent(
                "section-expand",
                {
                    _id: id,
                    name: internalName,
                    template: "1"
                },
                restaurantIdentifier || ""
            );
    }

    function handleExpand(event: React.SyntheticEvent, _expanded: boolean) {
        if (!alwaysExpanded) {
            setExpanded(_expanded);
            _expanded && launchExpandEvent();
            sections.setSectionState(id, _expanded);
        }
    }

    function shouldShowDotNotation(
        item: {
            item_id: IItem;
        },
        i: number
    ): boolean {
        const notations = _.get(item, "item_id.notation_links", []);
        if (!notations || !notations.length) {
            return false;
        }
        return notations.length - 1 !== i;
    }

    function getUrl(item: { item_id: IItem }) {
        const ans = item.item_id.button_url;
        if (!ans) return "#";
        if (ans.startsWith("http")) return ans;
        return `http://${ans}`;
    }

    function imageIsExpanded(imageId: string) {
        return imagesState[imageId];
    }

    function onBadgeClick(
        event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
        itemId: string,
        itemName: string,
        imageUrl: string
    ) {
        if (
            (event.target as HTMLElement).nodeName === "svg" ||
            (event.target as HTMLElement).nodeName === "path" ||
            (event.target as HTMLElement).nodeName === "span"
        ) {
            return setImagesState((prevState) => ({
                ...prevState,
                [itemId]: false
            }));
        }

        newEvent(
            "item-image-click",
            {
                _id: itemId,
                name: itemName,
                template: "1",
                image_url: imageUrl
            },
            restaurantIdentifier || ""
        );

        setImagesState((prevState) => ({
            ...prevState,
            [itemId]: true
        }));
    }

    // Handle the item counter input change
    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        id: string // Using _id as a string
    ) => {
        const newValue = event.target.value;

        // Check if newValue is a valid number
        if (!isNaN(Number(newValue))) {
            // Update the count only if it's a valid number
            const newCounts = counts.map((item: any) =>
                item._id === id
                    ? { ...item, item_length: Math.max(Number(newValue), 1) }
                    : item
            );
            setCounts(newCounts);

            // Update the item_length in cartData for the corresponding index
            const updatedData = cartData.map((item: any) =>
                item._id === id
                    ? { ...item, item_length: Math.max(Number(newValue), 1) }
                    : item
            );

            setCartData(cartItem.addCartItem(updatedData, true));
        }
    };

    const [descriptions, setDescriptions] = useState(() => {
        return cartItems.map((i: any) => {
            const matchingItem: any = cartData?.find(
                (item: any) => item?._id === i?.item_id?._id
            );
            return matchingItem ? matchingItem?.item_message || "" : "";
        });
    });

    function handlePopupOpen(i: number) {
        document.body.style.overflow =
            document.body.style.overflow === "hidden" ? "" : "hidden";
        document.documentElement.style.overflow =
            document.documentElement.style.overflow === "hidden"
                ? ""
                : "hidden";
        setDescriptions(() =>
            cartItems.map((i: any) => {
                const matchingItem: any = cartData?.find(
                    (item: any) => item?._id === i?.item_id?._id
                );
                return matchingItem ? matchingItem?.item_message || "" : "";
            })
        );
        setModifyPopup((prevState: any) => ({
            ...prevState,
            [i]: !prevState[i]
        }));
    }

    function handleMessageSave(Id: any, index: number) {
        const updatedData = cartData?.map((item: any, i: any) => {
            return item?._id === Id
                ? { ...item, item_message: `${descriptions[index]}` }
                : item;
        });
        setCartData(cartItem.addCartItem(updatedData, true));
        handlePopupOpen(index);
    }

    useEffect(() => {
        setDescriptions(() => {
            return cartItems.map((i: any) => {
                const matchingItem: any = cartData?.find((item: any) => {
                    return item?._id === i?.item_id?._id;
                });
                return matchingItem ? matchingItem?.item_message || "" : "";
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartData, cartItems]);

    const handleDescriptionChange = (index: any, value: any) => {
        const updatedDescriptions = [...descriptions];
        updatedDescriptions[index] = value;
        setDescriptions(updatedDescriptions);
    };
    const handleDescriptionReset = (index: any) => {
        handleDescriptionChange(index, "");
    };

    useEffect(() => {
        if (Object.values(modifyPopup).includes(true)) {
            if (typeof setModifyPopupPos === "function") {
                setModifyPopupPos(true);
            }
        } else {
            if (typeof setModifyPopupPos === "function") {
                setModifyPopupPos(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modifyPopup]);

    return (
        <Accordion
            style={{ marginBottom: "10px", backgroundColor: "transparent" }}
            disableGutters
            elevation={0}
            square
            expanded={expanded}
            onChange={handleExpand}
        >
            <AccordionSummary
                {...(fullMenu?.presenter_template.controls?.accordion_section &&
                    !alwaysExpanded && {
                        expandIcon: (
                            <ExpandMore
                                fontSize="large"
                                style={{
                                    color:
                                        fullMenu?.presenter_template?.fonts
                                            ?.section_links?.color || "#fff"
                                }}
                            />
                        )
                    })}
                aria-controls="panel1a-content"
                id="panel1a-header"
                color="primary"
                style={{
                    backgroundColor: template?.colors?.primary || "#CC5803",
                    color: "white",
                    ...(template.images?.section_background_image && {
                        backgroundImage: `url("${template.images?.section_background_image}")`,
                        backgroundSize: "cover"
                    })
                }}
            >
                <Typography
                    style={{
                        fontFamily:
                            template?.fonts?.section_links?.family || "Inter",
                        fontSize:
                            template?.fonts?.section_links?.size || "1.2rem",
                        fontWeight:
                            template?.fonts?.section_links?.weight || "500",
                        lineHeight:
                            template?.fonts?.section_links?.line_height || "1",
                        color:
                            template?.fonts?.section_links?.color || "#FFFFFF"
                    }}
                >
                    {sectionTitle}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography
                    style={{
                        fontFamily:
                            template?.fonts?.section_header?.family || "Inter",
                        fontWeight:
                            template?.fonts?.section_header?.weight || "400",
                        color: template?.fonts?.section_header?.color || "red",
                        fontSize:
                            template?.fonts?.section_header?.size || "12px",
                        lineHeight:
                            template.fonts?.section_header?.line_height || "1"
                    }}
                >
                    {sectionDescription}
                </Typography>
                {cartItems.map((item, i: number) => {
                    const isExpanded = modifyPopup[i];
                    const currentItem = counts.find(
                        (count: any) => count?._id === item?.item_id?._id
                    );
                    return !item?.item_id ? (
                        <React.Fragment key={i}></React.Fragment>
                    ) : (
                        <Root
                            template={template}
                            className={classes.itemContainer}
                            style={
                                // Check if it's the last item
                                i === cartItems.length - 1
                                    ? {}
                                    : item.item_id?.type === "title"
                                    ? {
                                          borderBottom:
                                              "2px solid " +
                                                  template?.colors?.primary ||
                                              "#222222",
                                          marginBottom: "8px"
                                      }
                                    : {
                                          borderBottom: `1px solid ${
                                              template?.colors?.background_2 ||
                                              "rgb(158, 158, 158, .5)"
                                          }`
                                      }
                            }
                            key={item.item_id?.display_name + i}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexWrap: imageIsExpanded(item.item_id._id)
                                        ? "wrap"
                                        : "nowrap"
                                }}
                            >
                                {template?.controls?.show_item_images &&
                                    item.item_id.image_url && (
                                        <div
                                            style={{
                                                width: imageIsExpanded(
                                                    item.item_id._id
                                                )
                                                    ? "100%"
                                                    : "60px",
                                                margin: "0",
                                                padding: "0",
                                                textAlign: "center",
                                                transition: "all 400ms linear",
                                                maxHeight: 400
                                            }}
                                        >
                                            <Badge
                                                color="secondary"
                                                invisible={
                                                    !imageIsExpanded(
                                                        item.item_id._id
                                                    )
                                                }
                                                onClick={(e) =>
                                                    onBadgeClick(
                                                        e,
                                                        item.item_id._id,
                                                        item.item_id
                                                            .display_name,
                                                        item.item_id.image_url
                                                    )
                                                }
                                                style={{
                                                    maxHeight: "100%",
                                                    width: "100%"
                                                }}
                                                sx={{
                                                    "& .MuiBadge-badge": {
                                                        color: "rgb(34,34,34)",
                                                        backgroundColor: "#fff",
                                                        width: "35px",
                                                        height: "35px",
                                                        borderRadius: "50%",
                                                        marginRight:
                                                            "35px !important",
                                                        marginTop:
                                                            "50px !important",
                                                        cursor: "pointer"
                                                    }
                                                }}
                                                badgeContent={
                                                    <CloseOutlined></CloseOutlined>
                                                }
                                            >
                                                <div
                                                    style={{
                                                        width: imageIsExpanded(
                                                            item.item_id._id
                                                        )
                                                            ? "100%"
                                                            : "60px",
                                                        height: imageIsExpanded(
                                                            item.item_id._id
                                                        )
                                                            ? "375px"
                                                            : "60px",
                                                        objectFit: "cover",
                                                        borderRadius:
                                                            template?.images
                                                                ?.item_image_border_radius ||
                                                            "6px",
                                                        backgroundImage: `url(${item.item_id.image_url})`,
                                                        backgroundSize: "cover",
                                                        backgroundPosition:
                                                            "center",
                                                        backgroundRepeat:
                                                            "no-repeat",
                                                        margin: "1rem 0",
                                                        cursor: "pointer"
                                                    }}
                                                    //src={item.item_id.image_url}
                                                    //alt={item.item_id.display_name}
                                                />
                                            </Badge>
                                        </div>
                                    )}
                                <div
                                    style={{
                                        marginLeft: 8
                                    }}
                                >
                                    <div
                                        className={classes.itemTitle}
                                        style={{
                                            ...(item.item_id?.type ===
                                                "title" && {
                                                fontSize: "1.3rem",
                                                fontWeight: "500",
                                                lineHeight: "1.3",
                                                marginBottom: "-18px"
                                            })
                                        }}
                                    >
                                        {item.item_id?.display_name}
                                    </div>
                                    <p className={classes.itemDescription}>
                                        {item.item_id.type === "item" &&
                                            item.item_id?.description}
                                    </p>
                                    <p className={classes.itemPrice}>
                                        {item.item_id?.price_variations?.map(
                                            (price, i) => (
                                                <React.Fragment
                                                    key={`${price.price}-${i}`}
                                                >
                                                    {showPrice(price, {
                                                        currency_symbol:
                                                            fullMenu?.currency_symbol,
                                                        currency_placement:
                                                            fullMenu?.currency_placement,
                                                        show_currency_symbol:
                                                            template?.controls
                                                                ?.show_currency_symbol,
                                                        show_empty_decimals:
                                                            template?.controls
                                                                ?.show_empty_decimals,
                                                        show_item_price:
                                                            template?.controls
                                                                ?.show_item_price
                                                    })}
                                                    {i !==
                                                        item.item_id
                                                            ?.price_variations
                                                            ?.length -
                                                            1 &&
                                                        getPriceSeparator(item)}
                                                </React.Fragment>
                                            )
                                        )}
                                    </p>
                                    <div className={classes.notationConatiner}>
                                        {showNotations &&
                                            item.item_id?.notation_links?.map(
                                                (notation, i) => (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent:
                                                                "flex-start",
                                                            alignItems:
                                                                "center",
                                                            marginTop: "1rem"
                                                        }}
                                                        key={
                                                            notation.notation_id
                                                                ?._id
                                                        }
                                                    >
                                                        <span
                                                            className={
                                                                classes.notation
                                                            }
                                                        >
                                                            {
                                                                notation
                                                                    .notation_id
                                                                    ?.display_name
                                                            }
                                                        </span>
                                                        {shouldShowDotNotation(
                                                            item,
                                                            i
                                                        ) && (
                                                            <span
                                                                className={
                                                                    classes.notationSeparator
                                                                }
                                                            ></span>
                                                        )}
                                                    </div>
                                                )
                                            )}
                                    </div>
                                    {item.item_id.button_status && (
                                        <form
                                            style={{ marginBottom: "1rem" }}
                                            action={getUrl(item)}
                                            target="_blank"
                                            rel="_noreferrer"
                                        >
                                            <button
                                                type="submit"
                                                onClick={() =>
                                                    newEvent(
                                                        "item-button-click",
                                                        {
                                                            template: "1",
                                                            name: item.item_id
                                                                .internal_name,
                                                            _id: item.item_id
                                                                ._id,
                                                            button_url:
                                                                item.item_id
                                                                    .button_url
                                                        },
                                                        restaurantIdentifier ||
                                                            ""
                                                    )
                                                }
                                                className={classes.itemButton}
                                            >
                                                {item.item_id.button_text}
                                            </button>
                                        </form>
                                    )}
                                </div>
                                <div style={{ marginLeft: "auto" }}>
                                    {item.item_id?.type !== "title" &&
                                        (() => {
                                            return (
                                                <>
                                                    <ButtonGroup className="itemCounterGroup">
                                                        <Box
                                                            className="itemCounter"
                                                            onClick={() => {
                                                                if (
                                                                    currentItem?.item_length !==
                                                                    1
                                                                ) {
                                                                    // If the item_length is not 1, decrease the count
                                                                    const newCounts =
                                                                        counts.map(
                                                                            (
                                                                                count: any
                                                                            ) =>
                                                                                count._id ===
                                                                                item
                                                                                    .item_id
                                                                                    ._id
                                                                                    ? {
                                                                                          ...count,
                                                                                          item_length:
                                                                                              Math.max(
                                                                                                  count.item_length -
                                                                                                      1,
                                                                                                  1
                                                                                              )
                                                                                      }
                                                                                    : count
                                                                        );
                                                                    setCounts(
                                                                        newCounts
                                                                    );

                                                                    // Update the item_length in cartData for the corresponding _id
                                                                    const updatedData =
                                                                        cartData.map(
                                                                            (
                                                                                cartItem: any
                                                                            ) =>
                                                                                cartItem._id ===
                                                                                item
                                                                                    .item_id
                                                                                    ._id
                                                                                    ? {
                                                                                          ...cartItem,
                                                                                          item_length:
                                                                                              Math.max(
                                                                                                  cartItem.item_length -
                                                                                                      1,
                                                                                                  1
                                                                                              )
                                                                                      }
                                                                                    : cartItem
                                                                        );
                                                                    setCartData(
                                                                        cartItem.addCartItem(
                                                                            updatedData,
                                                                            true
                                                                        )
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            {currentItem?.item_length ===
                                                            1 ? (
                                                                <Delete
                                                                    onClick={() =>
                                                                        handleRemoveItem(
                                                                            item
                                                                        )
                                                                    }
                                                                    fontSize="small"
                                                                />
                                                            ) : (
                                                                <RemoveRounded fontSize="small" />
                                                            )}
                                                        </Box>
                                                        <TextField
                                                            className="itemCounterText"
                                                            size="small"
                                                            onChange={(
                                                                event: any
                                                            ) =>
                                                                handleChange(
                                                                    event,
                                                                    item
                                                                        ?.item_id
                                                                        ?._id
                                                                )
                                                            }
                                                            value={
                                                                currentItem?.item_length
                                                            }
                                                        />
                                                        <Box
                                                            className="itemCounter"
                                                            onClick={() => {
                                                                const newCounts =
                                                                    counts.map(
                                                                        (
                                                                            count: any
                                                                        ) =>
                                                                            count._id ===
                                                                            item
                                                                                .item_id
                                                                                ._id
                                                                                ? {
                                                                                      ...count,
                                                                                      item_length:
                                                                                          Math.max(
                                                                                              count.item_length +
                                                                                                  1,
                                                                                              1
                                                                                          )
                                                                                  }
                                                                                : count
                                                                    );
                                                                setCounts(
                                                                    newCounts
                                                                );

                                                                // Update the item_length in cartData for the corresponding _id
                                                                const updatedData =
                                                                    cartData.map(
                                                                        (
                                                                            cartItem: any
                                                                        ) =>
                                                                            cartItem._id ===
                                                                            item
                                                                                .item_id
                                                                                ._id
                                                                                ? {
                                                                                      ...cartItem,
                                                                                      item_length:
                                                                                          Math.max(
                                                                                              cartItem.item_length +
                                                                                                  1,
                                                                                              1
                                                                                          )
                                                                                  }
                                                                                : cartItem
                                                                    );
                                                                setCartData(
                                                                    cartItem.addCartItem(
                                                                        updatedData,
                                                                        true
                                                                    )
                                                                );
                                                            }}
                                                        >
                                                            <AddRounded fontSize="small" />
                                                        </Box>
                                                    </ButtonGroup>
                                                </>
                                            );
                                        })()}
                                </div>
                            </div>
                            <div className="modifyItemWrapper">
                                <div className="modifyItem">
                                    {descriptions[i] !== "" ? (
                                        <EditIcon
                                            className={classes.addToCartIcon}
                                            onClick={() => handlePopupOpen(i)}
                                        />
                                    ) : (
                                        <AddToCart
                                            className={classes.addToCartIcon}
                                            onClick={() => handlePopupOpen(i)}
                                        />
                                    )}
                                    <span className={classes.itemPrice}>
                                        {descriptions[i] ||
                                            intl.formatMessage({
                                                id: "cart.modify_item"
                                            })}
                                    </span>
                                </div>
                                <div
                                    className={`modifyItemPopup ${
                                        isExpanded ? "openPopup" : ""
                                    }`}
                                >
                                    <div className="modifyItemPopupInner">
                                        <AddToCart
                                            onClick={() => handlePopupOpen(i)}
                                            className="closeModifyPopup"
                                        />
                                        <p className="modifyItemPopupTitle">
                                            {intl.formatMessage({
                                                id: "cart.modify_popup_title"
                                            })}
                                        </p>
                                        <div className="modifyPopupItem">
                                            <div
                                                className={classes.itemTitle}
                                                style={{
                                                    ...(item.item_id?.type ===
                                                        "title" && {
                                                        fontSize: "1.3rem",
                                                        fontWeight: "500",
                                                        lineHeight: "1.3",
                                                        marginBottom: "-18px"
                                                    })
                                                }}
                                            >
                                                {item.item_id?.display_name}
                                                {currentItem?.item_length >
                                                0 ? (
                                                    <div className="quantity-counter">{`${currentItem?.item_length}x`}</div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <p
                                                className={
                                                    classes.itemDescription
                                                }
                                            >
                                                {item.item_id.type === "item" &&
                                                    item.item_id?.description}
                                            </p>
                                            <p className={classes.itemPrice}>
                                                {item.item_id?.price_variations?.map(
                                                    (price, i) => (
                                                        <React.Fragment
                                                            key={`${price.price}-${i}`}
                                                        >
                                                            {showPrice(price, {
                                                                currency_symbol:
                                                                    fullMenu?.currency_symbol,
                                                                currency_placement:
                                                                    fullMenu?.currency_placement,
                                                                show_currency_symbol:
                                                                    template
                                                                        ?.controls
                                                                        ?.show_currency_symbol,
                                                                show_empty_decimals:
                                                                    template
                                                                        ?.controls
                                                                        ?.show_empty_decimals,
                                                                show_item_price:
                                                                    template
                                                                        ?.controls
                                                                        ?.show_item_price
                                                            })}
                                                            {i !==
                                                                item.item_id
                                                                    ?.price_variations
                                                                    ?.length -
                                                                    1 &&
                                                                getPriceSeparator(
                                                                    item
                                                                )}
                                                        </React.Fragment>
                                                    )
                                                )}
                                            </p>
                                        </div>
                                        <div className="modifyPopupMessage">
                                            <TextField
                                                multiline
                                                fullWidth
                                                label={intl.formatMessage({
                                                    id: "cart.message"
                                                })}
                                                rows={4}
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id: "cart.input_text"
                                                    }
                                                )}
                                                className="multiline"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                value={descriptions[i]}
                                                onChange={(e) =>
                                                    handleDescriptionChange(
                                                        i,
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            <div className="helper-text">
                                                {intl.formatMessage({
                                                    id: "cart.helper_text"
                                                })}
                                            </div>
                                            <div className="btnWrapper">
                                                {descriptions[i] !== "" ? (
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        className="popupBtn clearMessageBtn"
                                                        onClick={(e) =>
                                                            handleDescriptionReset(
                                                                i
                                                            )
                                                        }
                                                    >
                                                        {intl.formatMessage({
                                                            id: "cart.clear_btn"
                                                        })}
                                                    </Button>
                                                ) : (
                                                    ""
                                                )}
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className="popupBtn saveBtn"
                                                    onClick={() =>
                                                        handleMessageSave(
                                                            item?.item_id?._id,
                                                            i
                                                        )
                                                    }
                                                >
                                                    {intl.formatMessage({
                                                        id: "cart.save_btn"
                                                    })}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Root>
                    );
                })}
            </AccordionDetails>
        </Accordion>
    );
}
